import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Container, Grid } from '@mui/material'
import { webApiService } from '../../services'
import Detail from './components/Detail'
import Client from './components/Client'
import Checklist from './components/Checklist'
import Certificate from './components/Certificates'
import Contract from './components/Contract'
import Multimedia from './components/Multimedia'
import DeviceMobile from './components/DeviceMobile'
import DeviceOther from './components/DeviceOther'
import History from './components/History'
import { useDetailsContext } from './context/Details.context'
import Header from './components/Header'
import Questions from './components/Questions'
import BatteryCapacity from './components/BatteryCapacity'

const Details: React.FC = () => {
  const { ctx, saveCtx } = useDetailsContext()
  const { id } = useParams()

  const loadData = (): void => {
    Promise.all([
      webApiService.getAllById(String(id)),
      webApiService.getHistoryById(String(id)),
      webApiService.getChecklistQuestions(),
      webApiService.getEntrysById(String(id)),
      webApiService.getTradeinConfig().then((res) => res.data[0])
    ])
      .then(([tradeinDetails, historyList, questionList, entrys, tradeinConfig]) => {
        console.log('tradeinConfig => ', tradeinConfig)
        saveCtx({
          ...ctx, tradeinDetails: { ...tradeinDetails.data[0], imgBattery: [] }, statusHistory: historyList.data, questions: questionList.data, entrys: entrys.data, reload: false, displayContract: tradeinConfig.display_contract
        })
      })
      .catch((error) => {
        saveCtx({ ...ctx, reload: false })
        console.log('Error loading data:', error)
      })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (ctx.reload) {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.reload])
  console.log((ctx.tradeinDetails.status === 'RECHAZADO' && (ctx.tradeinDetails.imgBattery)))
  return (
    <Container sx={{ mt: 5 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} display='flex' alignItems='center' justifyContent='space-between'>
          {/* Encabezado mas botones de acción */}
          <Header />
        </Grid>
        {/* Detalle del Tradein */}
        <Grid item xs={12} md={3}>
          {ctx.tradeinDetails !== null ? <Detail /> : null}
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* Cliente */}
              {ctx.tradeinDetails !== null ? <Client /> : null}
            </Grid>
            <Grid item xs={6}>
              {/* Detalle del dispositivo Mobile */}
              {(ctx.tradeinDetails.sbu === 'Smartphone') ? <DeviceMobile /> : <DeviceOther />}
            </Grid>
            <Grid item xs={6}>
              {/* Historial de estados */}
              <History tradeinDetails={ctx.statusHistory} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {/* Questions */}
          {ctx.tradeinDetails !== null ? <Questions /> : null}
        </Grid>
        {ctx.tradeinDetails.id_status === 6 && ctx.tradeinDetails.sbu === 'Smartphone' && (
          <Grid item xs={12}>
            <Alert severity='error'>
              Lo sentimos, este dispositivo no aprobó la evaluación de Phonecheck. Puede reenviar el código para realizar una nueva evaluación.
            </Alert>
          </Grid>
        )}
        {ctx.tradeinDetails.id_status !== 6 && (
          <>
            <Grid item xs={12} md={6}>
              {ctx.tradeinDetails.sbu === 'Smartphone' ? <Certificate /> : <Checklist />}
            </Grid>

            <Grid item xs={12} md={6}>
              <BatteryCapacity />
            </Grid>

            {ctx.displayContract === 1 &&
              <Grid item xs={12}>
                <Contract />
              </Grid>}

            <Grid item xs={12}>
              <Multimedia />
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  )
}

export default Details
