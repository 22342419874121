import React from 'react'
import { Alert, Card, CardContent, Grid, Typography } from '@mui/material'
import { useDetailsContext } from '../context/Details.context'

const DeviceMobile: React.FC<any> = () => {
  const { ctx } = useDetailsContext()

  return (
    <>
      <Card sx={{ borderRadius: 1 }}>
        <CardContent>
          <Typography variant='h6' component='div' gutterBottom style={{ borderBottom: '1px solid #f3f1f1', fontSize: '18px' }}>
            Dispositivo
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='body1'><strong>Tipo:</strong> {ctx.tradeinDetails.sbu}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'><strong>Marca:</strong> <span className={ctx.tradeinDetails.brand !== ctx.tradeinDetails.make && ctx.tradeinDetails.recalculate === '1' ? 'text-decoration-line-through' : ''}>{ctx.tradeinDetails.brand}</span> {ctx.tradeinDetails.brand !== ctx.tradeinDetails.make && ctx.tradeinDetails.recalculate === '1' ? <span className='fw-bold'>{ctx.tradeinDetails.make}</span> : null}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'><strong>Modelo:</strong> <span className={ctx.tradeinDetails.model !== ctx.tradeinDetails.modelName && ctx.tradeinDetails.recalculate === '1' ? 'text-decoration-line-through' : ''}>{ctx.tradeinDetails.model}</span> {ctx.tradeinDetails.model !== ctx.tradeinDetails.modelName && ctx.tradeinDetails.recalculate === '1' ? <span className='fw-bold'>{ctx.tradeinDetails.modelName}</span> : null}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'><strong>Capacidad:</strong> <span className={ctx.tradeinDetails.capacity !== ctx.tradeinDetails.memory && ctx.tradeinDetails.recalculate === '1' ? 'text-decoration-line-through' : ''}>{ctx.tradeinDetails.capacity}</span> {ctx.tradeinDetails.capacity !== ctx.tradeinDetails.memory && ctx.tradeinDetails.recalculate === '1' ? <span className='fw-bold'>{ctx.tradeinDetails.memory}</span> : null}</Typography>
            </Grid>
            {ctx.tradeinDetails.sbu === 'Notebook' && (
              <>
                <Grid item xs={12}>
                  <Typography variant='body1'><strong>CPU:</strong> {ctx.tradeinDetails.cpu}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'><strong>Generación del CPU:</strong> {ctx.tradeinDetails.cpuGeneration}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'><strong>Pantalla:</strong> {ctx.tradeinDetails.screenSize}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'><strong>Memoria RAM:</strong> {ctx.tradeinDetails.ram}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'><strong>Almacenamiento:</strong> {ctx.tradeinDetails.storage}</Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant='body1'><strong>IMEI: </strong>
                {ctx.tradeinDetails.id_status === 1
                  ? (
                    <>
                      <Alert severity='info'>
                        Se requiere el resultado de la auto evaluación para obtener el imei.
                      </Alert>
                    </>
                    )
                  : ctx.tradeinDetails.id_status === 6
                    ? <Alert severity='error'>Evaluación fallida</Alert>
                    : ctx.tradeinDetails.serie}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default DeviceMobile
